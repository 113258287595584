@import './style.bundle.css';
@import './vendors.bundle.css';
// Required for compatibility with old icons
@import './line-awesome-old.scss';

* {
  --lemon: #e1cd00;
  --lemon-05: rgb(225, 205, 0, 0.5);
  --lemon-02: rgb(225, 205, 0, 0.2);
  --lemon-025: rgb(225, 205, 0, 0.25);
  --lemon-01: rgb(225, 205, 0, 0.1);
  --lemon-d10: rgb(202, 184, 0);
  --lemon-d20: rgb(180, 164, 0);
  --simple-blue: #3f99cc;
  --simple-blue-08: rgba(63, 153, 204, 0.8);
  --simple-blue-5: rgba(63, 153, 204, 0.5);
  --simple-blue-02: rgba(63, 153, 204, 0.2);
  --simple-blue-01: rgba(63, 153, 204, 0.1);
  --ocean-breeze: #2fb4ee;
  --simple-blue-dark: rgb(50, 140, 180);
  --dark: #161b2b;
  --dark-05: rgba(22, 27, 43, 0.5);
  --dark-02: rgba(22, 27, 43, 0.2);
  --dark-01: rgba(22, 27, 43, 0.1);
}

$deviceActivityWarningColor: #ff9e22;

input::-ms-clear {
  display: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: var(--simple-blue) !important;
  font-family: Poppins !important;
  overflow: visible !important;
  padding-right: 0 !important;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ul {
  list-style-type: none;
}

a {
  cursor: pointer;
}

hr {
  background-color: #fff;
  opacity: 0.1;
}

input {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.48px;
  box-shadow: none !important;
}

button:disabled {
  cursor: default;
}

select {
  &:focus {
    outline: none;
  }

  option {
    color: #000;
  }
}

.smallerCur {
  font-size: 0.8em;
}

.zIndex19 {
  position: relative;
  z-index: 19;
}

.zIndex20 {
  position: relative;
  z-index: 20;
}

.zIndex21 {
  position: relative;
  z-index: 21;
}

.btn-settings {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  border-radius: 15px;
  letter-spacing: 1.4px;
  border: 1px solid #000 !important;
  background: transparent !important;
  box-shadow: none !important;
  width: 83px;
  line-height: 0.5;

  &.btn-popup-canc {
    line-height: 0.9;
    width: auto;
    height: 39px;
    border: 1px solid #959ba2 !important;
    opacity: 0.9;
    color: var(--dark);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.64px;

    &:hover {
      border: 1px solid #959ba2 !important;
      background-color: #959ba2 !important;
      color: #fff;
    }
  }
}

.input-field.devices-checkbox-parent {
  height: auto !important;
  margin-bottom: 14px !important;
  margin-top: 40px;

  &:nth-last-child(1) {
    margin-bottom: 25px !important;
  }

  .switch-tooltip {
    margin-top: -3px;
  }
}

.btn {
  display: inline-block;
  line-height: 1.25;
  transition: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.65rem 1rem;

  &:disabled {
    opacity: 1;
  }
}

.btn.btn-secondary {
  i.fa-paper-plane {
    color: #fff;
    margin-right: 10px;
    font-size: 16px;
  }

  i.fa-save {
    color: var(--ocean-breeze);
    margin-right: 2px;
    margin-bottom: 2px;
    font-size: 16px;
  }

  i.fa-trash {
    color: var(--ocean-breeze);
    margin-left: -3px;
    margin-bottom: 4px;
    font-size: 20px;

    &:hover {
      color: #fff;
    }
  }

  i.fa-archive {
    color: var(--ocean-breeze);
    margin-left: -3px;
    margin-bottom: 1px;
    font-size: 18px;

    &:hover {
      color: #fff;
    }
  }
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  line-height: 22px;
  font-style: normal;
  text-wrap: nowrap;

  span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.button-primary {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #fff;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &.button-secondary {
    background-color: transparent;
    color: #161b2b;
    border: 1px solid var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  &.button-third {
    border: none;
    background-color: transparent;
    color: #000;


    &:hover {
      text-decoration: underline;
    }
  }

  &.button-fourth {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: #000;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;

      svg {
        fill: #fff;
      }

      i {
        color: #fff;
      }
    }
  }

  &.button-delete {
    background-color: transparent;
    border: 1px solid #F11D1D;
    color: #F11D1D;

    &:hover {
      background-color: #F11D1D;
      color: #fff;
    }
  }

  &.button-close {
    color: #fff;
    font-size: 24px;
    border: none;
    background-color: transparent;

    &:hover {
      color: var(--primary-color);
      background-color: transparent;
    }
  }

  &.with-icon {
    padding-left: 16px;
  }

  &.button-small {
    padding: 4px 20px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.battery-mode-btn-wrapper {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 4px;
  }
}

i.fa {
  font-size: 14px;
}

i.flaticon-event-calendar-symbol {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
}

i.fa-cog {
  font-size: 12px;
}

.user-name-user-icon:hover .btn.m-btn--hover-accent {
  &.m-btn--hover-accentik {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;

    i.la-user {
      color: #fff !important;
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color) !important;
    }
  }

  .la-cog {
    background-color: white !important;
    border-color: none !important;
  }
}

.pvInstallerAddBtn {
  /*  hack with add button in PV_INSTALLER PAGE */
  right: 0;
  position: absolute;
  margin-top: -50px;
}

.OEMAddBtn {
  right: 20px;
  position: absolute;
  margin-top: -49px;
}

.navig-btn-container {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navig-btn-dashb {
  margin-right: 9px;
  width: 41px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &.btn-today {
    width: auto !important;
    min-width: 78px;
  }

  &.btn-plus-zoom-dashb {
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }

  &.btn-min-zoom-dashb {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }

  &.btn-calendar-dashb {
    border: none;
    padding: 0;
    width: auto;
    font-size: 1.4em;

    &:hover {
      background: none;
      color: white;
    }
  }
}

.btn.m-btn--pill {
  &.m-btn--air.btn-secondary {
    &.btn-table-inst {
      color: var(--primary-color) !important;
    }
  }
}

.btn.m-btn--pill {
  &.m-btn--air.btn-secondary {
    &.btn-table-inst {
      background: transparent;
      border: 1px solid var(--primary-color) !important;
      border-radius: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      box-shadow: none !important;
      line-height: 0.5;

      &:disabled {
        color: #fff;
        border-color: #ffb822;
        opacity: 0.5;
      }

      &.send-log-request {
        line-height: 12px;
        font-size: 0.77em;
        text-transform: capitalize;
        color: #000;
      }

      &.exportBtn {
        line-height: 12px;
        font-size: 0.9em;
        text-transform: none;
      }

      &.white {
        color: #fff !important;
      }

      &.btn-inp-file-choose {
        border-radius: 0 3px 3px 0;
        line-height: 30px;
        padding: 3px 0;
      }

      &.btn-popup-sav {
        line-height: 0.9;
      }

      &.btn-save-chan {
        line-height: 0.7;
        font-size: 14px;
        color: var(--primary-color);
        font-weight: 400;
        letter-spacing: 1.4px;

        &:hover {
          background-color: var(--primary-color);
          color: #fff !important;
        }
      }

      &.btn-info-user.btn-popup-sav {
        height: 39px;
        opacity: 0.9;
        color: var(--ocean-breeze);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        background: #fff;
        border: 1px solid var(--ocean-breeze) !important;
        border-radius: 19px;
        box-shadow: none !important;
        line-height: 0.5 !important;

        &:hover {
          color: #fff !important;

          i.fa.fa-save {
            color: #fff !important;
          }
        }
      }

      &.btn-comp.btn-attach {
        border-radius: 0 3px 3px 0;
        line-height: 30px;
        margin: -3px 0 0 0;
      }

      .fa-save {
        color: var(--simple-blue-dark) !important;
      }

      &.delete-btn {
        border: 1px solid #f53920 !important;
        text-transform: capitalize;

        &:hover:hover:not(:disabled):not(.active),
        &:hover {
          background-color: #f53920 !important;
          border: 1px solid #f53920 !important;
        }
      }

      &.filter {
        border: 1px solid #fff !important;
        color: #fff !important;

        i.fa-filter {
          font-size: 16px;
          color: #fff;
        }

        &:hover:hover:not(:disabled):not(.active),
        &:hover {
          background-color: #fff !important;
          color: #000 !important;
          border: 1px solid #fff !important;

          i.fa-filter {
            color: #000 !important;
          }
        }
      }
    }
  }
}

.btn.m-btn--pill.btn-metal.m-btn.m-btn--custom.m-btn--label-brand.m-btn--bolder {
  border: 1px solid var(--primary-color) !important;
  background-color: transparent;
  color: var(--primary-color);

  &:hover {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;
  }
}

.change-btn,
.resend-btn {
  position: absolute;
  margin: 0;
  padding: 0;
  cursor: pointer;
  top: 50px;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.12px;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.change-btn {
  right: 0;
}

.resend-btn {
  left: 0;
}

.navig-btn-dashb-act {
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

a.btn-secondary.btn-table-inst.btn-info-user.right.dashboard-btn.white {
  color: #fff !important
}

.bootstrap-select>.dropdown-toggle.btn-light:focus {
  outline: none !important;
}

.m-btn--icon.m-btn--icon-only.m-btn--icon-dashb [class*=' la-cog'],
.m-btn--icon.m-btn--icon-only.m-btn--icon-dashb [class*=' la-user'] {
  font-size: 1.6rem;
}

.btn-focus.m-btn--air,
.btn-outline-focus.m-btn--air,
.m-btn--gradient-from-focus.m-btn--air {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-sm-us {
  padding-left: 0;
}

.cont-for-canc-btn {
  padding-left: 0;
}

.emp-set-btn-canc {
  height: auto;
  line-height: 0.7;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.4px;
  border: 1px solid #000 !important;
}

.form-group.m-form__group.input-field.input-field-for-change-btn {
  position: relative;
}

.btn.m-btn--air.btn-secondary.btn-table-inst:hover:hover:not(:disabled):not(.active) {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: none !important;
}

.btn.m-btn--hover-accent.trash {

  &:hover,
  &:focus,
  &:active {
    border-color: red !important;
    background-color: red !important;
  }
}

.btn.m-btn--air.btn-secondary.btn-settings.btn-popup-canc {
  color: #000 !important;

  &:hover:not(:disabled):not(.active) {
    border: 1px solid #959ba2 !important;
    background-color: #959ba2 !important;
    color: #fff !important;
  }

  &:focus {
    background-color: #d8d8d8 !important;
    border: 1px solid #d8d8d8 !important;
    color: #000000 !important;
  }
}

.btn.m-btn--air.btn-secondary.btn-settings:hover:not(:disabled):not(.active),
.btn.m-btn--air.btn-secondary.btn-settings:focus {
  background: #f0ad23 !important;
  border: 1px solid var(--primary-color) !important;
}

.btn-inp-file-choose {
  width: 25%;
  min-width: 160px;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.btn.m-btn--hover-accent:hover {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

a.nav-link.tab-heading {
  cursor: pointer;
}

.nav-pills {
  .nav-link.active {
    background-color: #fff;
    color: black;
  }

  .show>.nav-link {
    background-color: #fff;
  }
}

.nav.nav-pills .nav-link.active {
  color: black;
}

.m-tabs-line.m-tabs-line--danger.nav.nav-tabs .nav-link.active,
.m-tabs-line.m-tabs-line--danger.nav.nav-tabs .nav-link:hover {
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
}

.nav-link.tab-heading {
  background-color: rgba(64, 153, 204, 0.25);
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0;
  box-sizing: border-box;
  border-radius: 0;
  padding: 15px;

  &.active {
    background-color: #3f99cc;
    color: #fff !important;
  }
}

.react-tabs__tab--selected .nav-link.m-tabs__link {
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color);
}

.react-tabs__tab .m-tabs-line.m-tabs-line--danger.nav.nav-tabs .nav-link:hover {
  border-bottom: 4px solid var(--primary-color);
}

.react-tabs__tab .nav-link.m-tabs__link {
  min-height: 55px;
  cursor: pointer;
}

.m-tabs-line.nav.nav-tabs .nav-link {
  color: #000;
  letter-spacing: 0.54px;
  font-weight: 500;
  font-size: 18px;
}

.nav-link.m-tabs__link:focus {
  outline: none;
}

.react-select-container {
  font-size: 14px;

  .react-select__control {
    box-shadow: none !important;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;

    &:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .react-select__value-container {
      white-space: nowrap;
      padding-left: 0 !important;
      font-weight: 400;
      color: #000;
      letter-spacing: 0.48px;

      .react-select__placeholder,
      .react-select__single-value {
        color: #000;
      }
    }

    .react-select__indicators {
      .react-select__indicator {
        padding-right: 0;
        color: var(--primary-color);

        &:hover {
          color: var(--primary-color);
        }
      }

      .react-select__indicator-separator {
        display: none;
      }
    }

    &.react-select__control--menu-is-open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .react-select__menu {
    min-width: min-content;
    width: 100%;
    z-index: 11;
    color: #212529;

    .react-select__menu-list {
      border: none;
      border-radius: 3px;
      max-height: 160px;
    }

    .react-select__option {
      padding: 2px 5px;
      font-weight: 400;

      &:hover,
      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        background-color: #eaeaea;
        color: #212529;
      }
    }
  }

  &.react-select--is-disabled {
    .react-select__indicators {
      opacity: 0.5;
    }
  }
}

.modal-body {
  .react-select-container {
    .react-select__control {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .react-select__value-container {
        color: #000;

        .react-select__placeholder,
        .react-select__single-value {
          color: #000;
        }
      }

      .react-select__indicators {
        .react-select__indicator {
          color: #959ba2;

          &:hover {
            color: #959ba2;
          }
        }
      }
    }

    .react-select__menu {
      z-index: 11;
      color: #212529;

      .react-select__option {

        &:hover,
        &.react-select__option--is-focused,
        &.react-select__option--is-selected {
          background-color: #eaeaea;
          color: #212529;
        }
      }
    }
  }
}

.form-group.m-form__group.input-field {
  .customTextField .inputRoot.MuiInput-disabled-18 {

    &:before,
    &:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
      line-height: 1.1876em;
    }
  }

  div.m-input {
    width: 100% !important;
  }

  .inputRoot.MuiInput-disabled-18:before,
  .inputRoot.MuiInput-disabled-18:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  &.city-inp,
  &.zip-inp {
    padding: 0;
    margin-top: 14px !important;
  }

  &.city-inp.city-inp-comp-set,
  &.zip-inp.zip-inp-comp-set {
    padding: 0;
    margin-top: 0 !important;
  }
}

.m-form .m-form__group {
  padding-top: 0;
  padding-bottom: 0;

  .form-control-label,
  label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  &>.label_for_select {
    margin-bottom: 0;
    font-size: 13px;
  }
}

.flag-select {
  color: #fff !important;
  text-align: left;
  width: 100%;

  .country-flag .country-label {
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.48px;
  }

  img {
    top: 0 !important;
  }

  .selected--flag--option {
    cursor: pointer;
    padding: 0 !important;
    position: relative;
  }

  .arrow-down:hover {
    border-color: var(--primary-color) transparent transparent !important;
  }

  &.flagSelectClosed .arrow-down {
    color: var(--primary-color) !important;
    padding: 0 !important;
    position: absolute;
    right: 9px;
    overflow: hidden;
    border-color: var(--primary-color) transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    border-spacing: 0;
    border-collapse: separate;
    text-align: center;
    box-sizing: border-box;
    top: 5px;
  }
}

.gener-sett-inside-cont {
  margin-top: 15px;

  &.smu-set-bl {
    margin-top: 0;
    border: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);

    &.smu-set-bl-last {
      border-right: none;
    }
  }
}

.m-dropdown__header.m--align-center {
  background: #fff;
  background-size: cover;
  background-position: center;
}

.m-dropdown__wrapper {
  width: 325px;
  box-shadow: 0 3px 15px #00000029;

  .m-dropdown__header {
    padding: 20px 20px;
    border-radius: 4px 4px 0 0;
  }

  .m-dropdown__body {
    padding: 20px;
    border-radius: 0 0 4px 4px;
    background-color: var(--simple-blue) !important;
  }
}

.m-dropdown__arrow.m-dropdown__arrow--right.m-dropdown__arrow--adjust {
  position: absolute;
  top: -11px;
  color: var(--simple-blue);
  left: auto;
  right: 2.5px !important;
  line-height: 0;
  display: inline-block;
  overflow: hidden;
  height: 11px;
  width: 40px;
  left: 50%;
  margin-left: -20px;
}

.m-dropdown__arrow:before {
  display: inline-block;
  font-family: Metronic, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 0;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: '';
  position: relative;
  top: 100%;
  margin-top: 11px;
  font-size: 40px;
}

.dropdown-menu.dropdown-menu-right.show {
  position: absolute;
  transform: translate3d(-196px, 27px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.dropdown-item {
  &.dropdown-item-dashb {
    padding: 0.25rem 1rem;
  }
}

@keyframes showNoJSWarning {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.noJavascriptWarning {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: showNoJSWarning;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 12s;
  animation-fill-mode: both;
}

.loadingTextWithBG {
  background: var(--simple-blue);
  color: var(--primary-color);
  font-size: 2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.05s ease-out;
  opacity: 0;
  visibility: hidden;

  &.loading {
    opacity: 1;
    visibility: visible;
  }
}

.uploadFileName {
  max-width: 100%;
  margin-top: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  padding: 5px 15px 0 15px;
  box-sizing: border-box;
}

.marginBottom {
  margin-bottom: 15px;
}

.marginBottom6px {
  margin-bottom: 6px !important;
}

.QRScannerBOption {
  padding: 10px 0;
  font-size: 16px;
  color: white;
  opacity: 0.6;

  .bOptionButton {
    display: inline-block;
    line-height: 20px;
    padding: 0 5px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 18px;
    color: var(--primary-color);

    &:hover {
      color: #fff;
    }
  }
}

.mainPreLoaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 158px;

  .mainPreLoader {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Select-input {
  padding-left: 0 !important;
}

.firstLetterToUpCase:first-letter {
  text-transform: uppercase;
}

.whiteStatusText {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  margin: 10px 15px;
}

.curr-page:hover {
  cursor: initial !important;
  background-color: initial !important;
}

.topbar-dashb {
  background-color: #fff;
}

.smu_pvi {
  margin-top: 45px;
}

.m-card-user.m-card-user--skin-dark .m-card-user__details {
  .m-card-user__email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 216px;
  }

  .m-card-user__name {
    color: #000;
  }

  .m-card-user__email {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: var(--primary-color);

      &:after {
        border-bottom: 1px solid var(--primary-color);
      }
    }
  }
}

.loadingText {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 1.5em;
  margin-top: 50px;
}

.user-name-user-icon:hover .user-name-topbar {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-color) !important;
}

.m-grid__item.m-grid__item--fluid.m-login__wrapper {
  align-items: center;
}

.Select-option {
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.48px;
  padding: 0px 20px 3px 8px;
  margin: 4px 0;

  &.is-focused {
    background-color: #eaeaea !important;
  }
}

.Select-menu {
  position: absolute;
  z-index: 999999;
  border: 1px solid #bdbbbb;
  border-radius: 3px;
  background: #ffffff;
  margin-top: 8px;
  padding: 8px 0;
  max-height: 160px;
  overflow: auto;
  width: 100%;
}

.flags-select-label {
  margin-bottom: 0;
  color: var(--primary-color);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.39px;
}

.progress-energy-percent {
  flex-grow: 0;
  padding-left: 0;
  padding-right: 0;
  opacity: 0.9;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.la-angle-left.la-angle-left-stat.la,
.la-angle-right.la-angle-right-stat.la {
  color: var(--primary-color);
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 100;
  margin-top: -20px;
  padding: 10px 10px;
}

.la-angle-right.la-angle-right-stat.la {
  right: 5px;

  &:hover {
    color: #000;
  }
}

.la-angle-left.la-angle-left-stat.la {
  left: 5px;

  &:hover {
    color: #000;
  }
}

.sm-en-cont {
  padding: 5px 0;
  position: relative;
}

.en-stat-data-h {
  padding-top: 12px;
  padding-bottom: 5px;
}

select.form-control.car-charger-input:not([size]):not([multiple]) {
  height: calc(2.39rem + 6px);
  cursor: pointer;
}

.m-checkbox.m-checkbox--light>input:checked~span {
  border: 1px solid var(--primary-color) !important;
}

/* !Fix checkbox orange */
.m-checkbox.m-checkbox--light>span:after {
  border: solid var(--primary-color);
}

.m-checkbox.m-checkbox--light.checkbox-terms,
.m-checkbox.m-checkbox--light.checkbox-terms a {
  color: var(--primary-color) !important;
  font-weight: 500 !important;
}

input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='password']:not(.browser-default):focus:not([readonly]) {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  box-shadow: none;
}

input[type="text"]:not(.browser-default):focus {
  border: none !important;
}

.feedIn-table {

  input[type=text]:not(.browser-default),
  input[type='text']:not(.browser-default):focus {
    color: #000;
  }
}

.user-name-icon {
  cursor: pointer;
}

.user-name-user-icon {
  display: flex;
  flex-direction: justify;
}

.user-name-topbar {
  margin-right: 8px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
}

.la.la-user.la-user-comp:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

div[class*='MuiPaper-root-'] {
  background: transparent !important;
}

.m-nav .m-nav__item>.m-nav__link {
  .m-nav__link-icon {
    color: var(--primary-color);
  }

  .m-nav__link-text {
    color: #fff;
  }

  &:hover {
    &.m-nav__link-title {
      background-color: var(--primary-color);
    }
  }
}

.m-nav .m-nav__separator {
  border-bottom: 1px solid rgba(244, 245, 248, 0.1);
}

.en-values-container {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 0;
  padding-bottom: 0;
}

.is-clearable.is-searchable {
  border: none !important;
}

.table-details-user-nifo {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.table-tariff-header {
  white-space: nowrap;
  height: 34px;
}

.dev-nav-prior {
  border: 1px solid var(--lemon-05);
  padding: 8px 14px;
  border-radius: 2px !important;
  margin: 0 5px;
  text-align: right;
  transition: all 0.2s ease-in;
}

.priority-column {
  position: relative;
}

.dev-nav-arrow {
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 5px;
}

.form-control.m-input--air:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.input-field {
  height: 57px !important;
  margin-bottom: 20px !important;
}

.input-field.note-input-field {
  height: auto !important;

  textarea {
    min-height: 25px;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.39rem + 4px);
}

.m-tabs-line .m-tabs__item {
  margin-right: 20px;
}

.nav-link.m-tabs__link {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  color: var(--primary-color);

  &.ip-link {
    display: inline-block;
    font-size: 14px;
    padding-left: 0;
    color: var(--dark) !important;

    .ip-name {
      color: var(--primary-color);
      font-size: 20px;
      font-weight: 400;
    }

    .ip-value {
      color: var(--dark);
    }

    &:hover {
      color: #fff !important;

      .ip-value {
        color: #fff !important;
      }
    }
  }
}

.m-tabs__item {
  &.ip-item {
    color: var(--dark) !important;

    &:hover {
      background-color: #4f535d;

      .ip-link {
        color: #fff;
      }
    }
  }
}

.ip-list {
  padding: 0;
}

.noChargingFound {
  color: #000;
  font-size: 18px;
  padding: 0 50px;
}

input[type="text"]:not(.browser-default):disabled {
  border-bottom: none;
  color: #fff;

  &+label {
    color: #fff;
  }
}

.form-control {
  border-radius: 2px;
  background-color: #40424d;

  &:focus {
    background: transparent;
    color: #fff;
    border-color: #fff;
  }
}

.m-portlet__head-caption {
  margin-top: 8px;
}

.m-portlet.smu_pvi {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.m-portlet .m-portlet__head.header-table {
  border: none;
}

.m-portlet.m-portlet--tabs .m-portlet__head {
  padding: 0 3.9rem;
}

.m-portlet__head {
  background-color: #25272d;
  border-bottom: none !important;

  .table-navig {
    background-color: #fff;
    height: auto;
    padding: 0;

    li:first-child {
      margin-left: 29px !important;
    }
  }
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  position: relative;
}

.form-control.m-input.m-input--solid.search {
  color: #fff;
  height: 37px;
  border-radius: 2px;
  background-color: var(--simple-blue);
  border-color: var(--simple-blue);
  line-height: 37px;
}

.m-grid__item.m-grid__item--fluid.m-grid.m-grid--hor.m-login.m-login--signin.m-login--2.m-login-2--skin-1 {
  overflow: hidden;
}

.input-field-for-ch-pass {
  margin-bottom: 5px !important;
  position: relative;
}

.form-group.m-form__group.input-field.input-field-for-ch-pass {
  margin-bottom: 20px !important;
}

.m-checkbox.m-checkbox--state-warning>input:checked~span {
  border: 1px solid;
}

.fa.fa-caret-right {
  color: #898b96;
}

.fa.fa-caret-down {
  color: var(--primary-color);
}

.m-checkbox.m-checkbox--state-warning>span {
  border: 1px solid var(--primary-color);
}

.m--font-success {
  color: #4ccd37 !important;
}

.m-badge.m-badge--success {
  background-color: #4ccd37;
}

.m-card-user__name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 219px;
}

.m-checkbox.m-checkbox--brand.m-checkbox--solid>span:after {
  border: solid #25272d;
}

.error_status_yes {
  color: #000 !important;

  &.warning {
    color: $deviceActivityWarningColor !important;
  }
}

.update-name-inp {
  border-radius: 2px 0 0 2px;
  border: 1px solid #ffffff80 !important;
  padding: 3px 18px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.48px;
  line-height: 30px;
  width: 65%;
  min-width: 240px;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 100%;
  }

  &::placeholder {
    color: #ffffff80;
  }
}

.sear-sel-inps {
  margin-top: 31px;
}

.pop-up-form {
  padding: 0;
}

.modal-content {
  background-color: #f9f9f9;
  overflow: visible;

  .modal-header {
    padding: 0 26px;
    border-bottom: none;
    background-color: #fff;

    .modal-title {
      color: var(--dark);
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.72px;
      margin: 40px 38px 35px 38px;
    }
  }

  .modal-body {
    padding-bottom: 0;
    background-color: #f9f9f9;

    .info {
      color: #161B2B;
    }

    .text-confirmation {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      padding: 20px 0;
    }

    .text-confirmation-detailed {
      font-style: italic;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      padding: 0px 50px 24px;
    }
  }

  .modal-footer {
    padding: 14px 62px 25px 62px;
    background-color: #f9f9f9;
    padding-top: 20px !important;
    border-top: none !important;
    border: none;

    &.highlighted {
      margin-top: 10px;
      border-top: 1px solid rgba(44, 46, 53, 0.10) !important;
    }
  }
}

.settings-header-block {
  background-color: #f9f9f9 !important;
  align-items: center;
}

.m-tabs-line.m-tabs-line--danger a.m-tabs__link.active,
.m-tabs-line.m-tabs-line--danger a.m-tabs__link:hover {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.user-name {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.51px;
  margin: 12px 0;
}

.nav.nav-pills .nav-item {
  margin-left: 0;
}

.active-datatable-row {
  background-color: #e8e8e8;
}

.la-info-circle {
  color: var(--primary-color);
  cursor: pointer;

  &.inform-web {
    font-size: 25px;
    text-decoration: none;
  }
}

.warning-par {
  margin-top: 86px;
  font-size: 14px;
  font-weight: 400;

  i {
    font-weight: 500;
  }
}

.tooltipInsideRow {
  margin-top: 30px;
}

.tooltipInsideRowHeader {
  margin-top: 5px;
}

.tooltip-inform {
  position: relative;
  display: inline-block;

  .tooltiptext-inform {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
  }
}

.tooltip-inform:hover .tooltiptext-inform {
  visibility: visible;
}

.dashboard-link {
  text-transform: capitalize;
  color: var(--primary-color) !important;
  font-size: 14px;
  font-weight: 400;
  width: auto;
  position: absolute;
}

.city-zip {
  margin: 0;
}

.city-inp {
  margin: 0 !important;
}

.zip-inp {
  margin-right: 0 !important;
}

.nav-settings {
  padding-top: 15px;
  padding-bottom: 17px;
  background-color: #fff;
}

.profile-settings {
  .form-group.m-form__group.input-field {
    .customTextField .inputRoot.MuiInput-disabled-18 {

      &:before,
      &:after {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
      }
    }

    .inputRoot.MuiInput-disabled-18:before,
    .inputRoot.MuiInput-disabled-18:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.m--font-bold.signal {
  font-weight: 400;
  font-size: 16px;
}

.inputCustomWarning {
  padding: 0 !important;
  font-size: 11px !important;
  margin: 4px 0 0 !important;

  &.m-0 {
    margin: 0 !important;
  }

  &.pl-2 {
    padding-left: 10px !important;
  }
}

.textarea-custom-warning {
  min-height: 16.5px;
  font-size: 11px;
  font-weight: 400;
  color: #f4516c;
  margin: 4px 0 0;
  padding: 0;
}

.settings-user-prof {
  background-color: var(--simple-blue);

  .MuiInput-underline,
  .MuiInput-underline::after,
  .MuiInput-underline:hover::after,
  .MuiInput-underline:focus::after {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .react-select-container {
    .react-select__control {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      &:hover {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }

      .react-select__value-container {
        color: #000;

        .react-select__placeholder,
        .react-select__single-value {
          color: #fff;
        }
      }
    }

    .react-select__menu {
      z-index: 11;
      color: #212529;

      .react-select__option {

        &:hover,
        &.react-select__option--is-focused,
        &.react-select__option--is-selected {
          background-color: #eaeaea;
          color: #212529;
        }
      }
    }
  }
}

.limited-feedIn-wrapper {
  .react-select-container {
    .react-select__control {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      &:hover {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }

      .react-select__value-container {
        color: #fff;

        .react-select__placeholder,
        .react-select__single-value {
          color: #fff;
        }
      }
    }
  }

  .affected-device {
    color: #fff;
  }

  .MuiInput-underline,
  .MuiInput-underline::after,
  .MuiInput-underline:hover::after,
  .MuiInput-underline:focus::after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.set-prof-head {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  margin-bottom: 0;
}

.firmware-update-title {
  color: #fff;
}

.profile-settings,
.nav-settings,
.settings-header-block {
  .set-prof-head {
    color: #000;
  }
}

label.label-for-sett {
  font-size: 16px;
  color: #c1c1c1;
}

div [class*='MuiPopover-paper-'] {
  z-index: 1000;
}

.form-control.m-input--air {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.cont-sett-user-lang-select.seet {
  height: 75px;
}

select.form-control.sel-seet:not([size]):not([multiple]) {
  height: calc(1.39rem + 17px);
}

.label_for_select.label-for-sett {
  font-size: 13px;
}

.cont-sett-user-lang-select {
  position: relative;
}

.sett-user-lang-select {
  position: absolute;
  top: 23px;
}

.settings-user-details {
  margin-top: 92px;
}

.user-photo img {
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #25272d;
  width: 135px;
  height: 135px;
}

.email-text-td {
  color: #68b0ee !important;

  a {
    color: #fff;

    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.email-text-td:hover {
  text-decoration: underline;
}

.react-tabs__tab-list {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.react-tabs__tab {
  display: inline-block;
}

.load-guard-tab-item {
  margin-right: 190px;
}

.react-tabs__tab .tabs-cont {
  border: none;
}

.nav-item.m-tabs__item:focus {
  outline: none;
}

.nav.nav-tabs.m-tabs-line.m-tabs-line--danger.m-tabs-line--2x.m-tabs-line--right.nav-tab-links {
  outline: none;
}

:focus {
  outline: none;
}

.react-tabs__tab .nav-tabs .nav-item {
  margin-bottom: -12px;
}

.remem-checkbox {
  opacity: 1;
  color: #000 !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.m-tabs-line {
  border-bottom: none;

  a.m-tabs__link {
    color: #fff;
    letter-spacing: 0.48px;
    font-weight: 500;
    font-size: 18px;
  }
}

.la.la-user-comp {
  font-size: 1.6rem;
  border: 1px solid #3e404c;
  padding: 3px;
  border-radius: 50%;
}

.nav.nav-tabs {
  margin-bottom: 0;
}

.table-details-user-nifo.yell {
  color: var(--primary-color);
}

.table-details-user-nifo-value {
  color: #fff;
  font-weight: 500;
}

.table-details-user-nifo-value-type {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.tarif-header {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  letter-spacing: 0.48px;
  font-size: 16px;
}

.tabs-cont {
  margin: 0;
  border: 1px solid #40424d;
  border-bottom: none;
  box-sizing: border-box;
}

.nav-tab-links {
  border-bottom: none;
}

.gener-sett-soft-container {
  margin-top: 27px;
  padding-bottom: 0;
}

.general-sett-modal-label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.gener-sett-header {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 27px;
  margin-top: 10px;
}

.title-create-acc {
  margin-bottom: 102px;
}

.nav.nav-pills.nav-tabs-items {
  margin-bottom: 0;
}

.m-badge.m-badge--metal.gener-sett-soft-select {
  background-color: transparent;
  border: 1px solid #fff;
}

.table-comp-mail {
  color: #68b0ee !important;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.48px;
}

.company-logo img {
  padding: 21px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #25272d;
}

.company-details-table .company-detail-td {
  padding: 0 100px 0 25px;
}

.smu-select-sp {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5) !important;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.smu-select-sp-num {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5) !important;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.input-filed-flags-select {
  height: 48px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 25px;
}

.flag-options {
  width: 100%;
}

.flag-option.has-label {
  color: black;
}

.input-field-street {
  margin-bottom: 0 !important;
}

.MuiInputLabel-root.inputLabel.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.8) !important;

  .MuiFormLabel-filled {
    font-size: 16px;
  }
}

.confirmation-field .MuiInputLabel-root.inputLabel.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.8) !important;
  margin-top: -5px;

  .MuiFormLabel-filled {
    font-size: 16px;
  }
}

.MuiFormLabel-root-10 {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.39px;
}

.input-field-checkbox-terms {
  height: 14px !important;
}

.simplePopoverRoot {
  position: absolute !important;
}

.simplePopoverParent {
  overflow: visible !important;
  margin-top: 20px;
  z-index: 1000;
  background: transparent !important;
}

.settings-header-block.device-top-bar {
  min-height: auto;
  height: 62px;
}

.nav-tabs .nav-item {
  margin-bottom: -6px;
}

.m-tabs-line.m-tabs-line--2x a.m-tabs__link {
  border-bottom-width: 4px !important;
}

.input-field.input-field-sm {
  margin-bottom: 9px !important;
}

.email-detail-table a {
  cursor: pointer;
  color: #68b0ee;
}

.switch-tooltip {
  font-size: 20px;
  float: right;
  margin: 0 0 -45px;
  margin-top: 16px;
  position: relative;
  z-index: 10;

  &.select {
    margin: 18px 24px -50px 5px;
  }
}

.switch-tooltip.tooltipInSelect {
  display: inline-block;
  font-size: 16px;
  float: none;
  margin: -5px 6px -3px;
}

.progress .la.la-info-circle {
  font-size: 16px;
  color: #fff;
  line-height: 19px;
}

.modal-body {
  .form-group.m-form__group.input-field {

    input,
    input:disabled {
      color: #000 !important;
      -webkit-text-fill-color: #000;
    }
  }
}

.m-login__form.m-form {
  .form-group.m-form__group.input-field {

    label,
    input {
      color: #000;
      font-size: 16px;
      letter-spacing: 0;
    }

    input {
      border-bottom: 1px solid #000000cc;
      caret-color: var(--primary-color);
    }
  }
}

.m-input-icon .m-input-icon__icon i {
  color: #fff;
}

.form-control.m-input.m-input--solid.search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
}

.m-datatable>.m-datatable__pager>.m-datatable__pager-nav>li>.m-datatable__pager-link {
  color: rgba(255, 255, 255, 0.5);

  .la {

    &.la-angle-right,
    &.la-angle-double-right {
      color: var(--primary-color);

      &:hover {
        color: #fff;
      }
    }
  }

  &.m-datatable__pager-link--disabled {
    background-color: #65add6;
    color: var(--primary-color);
  }
}

.btn.m-btn--hover-accent:not(.btn-secondary):not(.btn-outline-light) i {
  color: #161B2B80;
}

.details-wrapper {
  .btn.m-btn--hover-accent:not(.btn-secondary):not(.btn-outline-light) i {
    color: #fff;
  }
}

.user-info {
  background-color: var(--simple-blue);
}

.react-tabs {
  background-color: #fff;
}

.m-nav {
  .m-nav__item:hover:not(.m-nav__item--disabled)>.m-nav__link-icon {
    .m-nav__link-icon {
      color: var(--primary-color) !important;
    }
  }
}

.m-nav__link-title {
  .m-nav__link-icon.flaticon-user-ok {
    color: var(--primary-color) !important;

    &:hover {
      color: var(--primary-color) !important;
    }
  }

  .m-nav__link-text {
    color: #fff !important;

    &:hover {
      color: var(--primary-color) !important;
    }
  }
}

.la.la-search {
  color: rgba(255, 255, 255, 0.5) !important;
}

.flaticon-event-calendar-symbol {
  color: #000 !important;
}

.modal-body {
  padding: 10px 50px 0 !important;

  .Select-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .Select-placeholder {
    color: #000 !important;
  }

  .Select.has-value.Select--single>.Select-control .Select-value .Select-value-label {
    color: #000 !important;
  }

  .ip-field {
    width: 100%;
  }

  .m-login__form.m-form .form-group.m-form__group.input-field input {
    border: none;
  }

  .inputRoot:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .tarif-header {
    color: rgba(0, 0, 0, 0.5);
  }
}

.modal-footer {
  .btn.m-btn--air.btn-secondary.btn-table-inst:hover:hover:not(:disabled):not(.active) {
    background: var(--ocean-breeze) !important;
    border: 1px solid var(--ocean-breeze) !important;
  }

  .btn-info-user.btn-popup-sav {
    color: var(--ocean-breeze) !important;
    background: #fff !important;
    border: 1px solid var(--ocean-breeze) !important;

    .fa-save,
    .fa-paper-plane {
      color: var(--ocean-breeze) !important;
    }

    &:hover {
      color: #fff !important;
      background: var(--ocean-breeze) !important;
      border: 1px solid var(--ocean-breeze) !important;

      .fa-save,
      .fa-paper-plane {
        color: #fff !important;
      }
    }
  }
}

.btn-popup-sav {
  color: #fff;
  background-color: var(--simple-blue);

  &:hover {
    color: #fff;
    background-color: var(--simple-blue-dark);
    border: 1px solid var(--ocean-breeze) !important;
  }
}

.Select .Select-arrow-zone {
  &>.Select-arrow {
    border-top-color: var(--primary-color);
  }

  &:hover>.Select-arrow {
    border-top-color: var(--lemon-05);
  }
}

#pv_installer-reg {
  background-color: #53a3d1;

  option {
    background-color: #fff;
    color: var(--dark);
  }
}

.nav-monitoring {
  .nav-tabs {
    .nav-link.m-tabs__link {
      color: #fff;
    }
  }
}

#react-select-4--value,
#react-select-5--value {
  color: #fff;
}

.btn.m-btn--air.btn-secondary.btn-popup-canc {
  background-color: #fff !important;
  border: 1px solid #959ba2 !important;
  color: var(--dark);

  &:hover {
    background-color: #000 !important;
    color: #fff !important;
  }
}

#monitoringNote {
  background-color: #f9f9f9;
}

.notFound {
  color: #000;
}

#generalSearch {
  background: transparent;
  border: 1px solid #FFFFFF19;
  border-radius: 2px;

  &:focus {
    background: transparent;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

#addEndUsersToSupport {
  color: #161B2B !important;
  background: transparent !important;
  border: 1px solid #161B2B33 !important;
  border-radius: 2px;

  &::placeholder {
    color: #898B96 !important;
  }
}

#addEndUsersToSupport+.m-input-icon__icon.m-input-icon__icon--left.search {
  i.la.la-search {
    color: #898B96 !important;
  }
}

.m-login__form.m-form {
  .form-group.m-form__group.input-field {
    input.PhoneInputInput {
      border: none;
    }

    .PhoneInput {
      &::before {
        border-bottom: 1px solid var(--dark-05) !important;
      }

      &::after {
        border-bottom: 2px solid var(--primary-color) !important;
      }
    }

    .input-filed-flags-select {
      border-bottom-color: var(--dark-05);
    }

    .flag-select__option__label {
      color: var(--dark);
    }
  }
}

.dataSMID {
  color: #161b2bcc !important;
}

.calendarModal .modal-body {
  padding: 0 !important;
}

.btn.m-btn--air.btn-secondary.btn-table-inst.btn-comp {
  color: #fff;
  background: var(--primary-color);
}

.btn.m-btn--air.btn-secondary.btn-table-inst.btn-comp:hover:hover:not(:disabled):not(.active) {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.bg-logo,
.lg-logo {
  width: 156px;
  height: 45px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-info-user.right {
  color: var(--primary-color);

  &:hover {
    color: #fff;
  }
}

.switch-tooltip+.m-checkbox.remem-checkbox {
  padding-right: 26px;
}

.modal-dialog {
  .Select-control {

    .Select-value,
    .Select-placeholder {
      width: 350px;
    }
  }

  .switch-tooltip.select {
    margin-top: 20px;
  }
}

.m-login__form.m-form .form-group.m-form__group.input-field .Select-input {

  label,
  input {
    color: var(--dark);
  }
}

.avatar-logo {
  width: 135px;
  height: 100%;
}

.control-panel {
  margin: 30px 0 0 0;
}

.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-info-user.btn-popup-sav {
  color: var(--ocean-breeze) !important;
}

.date-dashb {
  color: #161b2b;
  line-height: 20px;
  font-weight: 300;
  margin-right: 14px;
  font-size: 16px;
}

@media (max-width: 1400px) {
  .user-name-topbar {
    max-width: 147px;
  }
}

@media (max-width: 1200px) {
  .gener-sett-inside-cont {
    margin-top: 15px;
  }

  .gener-sett-soft {
    height: calc(100% - 20px);
  }

  .gener-sett-inside-cont.smu-set-bl {
    border: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  .user-name-topbar {
    display: none;
    max-width: 147px;
  }

  .header-det-energy-stat {
    font-size: 1em;
  }

  .det-energy-stat-kwh {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .gener-sett-soft {
    height: calc(100% - 20px);
  }

  .car-charger-input {
    width: 65%;
    display: inline-block !important;
  }
}

@media (max-width: 1034px) {
  .nav-buttons-dashb:first-child .navig-btn-dashb {
    margin-right: 0;
  }
}

@media (max-width: 992px) {
  .gener-sett-inside-cont.smu-set-bl {
    border: none;
    border-bottom: 1px solid rgba(244, 245, 248, 0.1);
  }

  .gener-sett-inside-cont.smu-set-bl.smu-set-bl-last {
    border-bottom: none;
  }

  .company-details-table .company-detail-td {
    padding: 0 10px 0 25px;
  }

  .car-charger-select {
    margin-top: 25px;
  }

  .nav-buttons-dashb {
    padding: 0 0 0 15px;

    &:last-child {
      padding-right: 5px;
    }
  }

  .liveDataStatus {
    margin: 0;
  }

  .date-dashb {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .dashboardApp .settings-header-block {
    min-height: 201px;
    align-items: center;
  }
}

@media (max-width: 770px) {
  .det-energy-stat {
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {

  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-sub .m-link,
  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-sub .m-checkbox {
    font-size: 12px;
  }

  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-sub .m-checkbox.terms {
    font-size: 12px;
  }

  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-action .m-login__btn.forgot-button {
    font-size: 12px;
  }

  .btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst {
    margin-top: 0;
  }

  .thank-you-cont {
    margin-top: 30px;
  }
}

.note-field {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  resize: none;

  &::placeholder {
    color: #000;
  }
}

.max-width-550 {
  max-width: 550px;
}

@media (max-width: 1019px) {
  .car-charger-img {
    display: none;
  }
}

@media (max-width: 723px) and (min-width: 660px) {
  .header-en-value {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .en-value {
    font-size: 33px;
  }

  .car-charger-select {
    margin-top: 12px;

    .form-group {
      font-size: 12px;

      select {
        height: 1.8rem;
      }
    }
  }
}

@media (max-width: 1019px) and (min-width: 724px) {
  .header-en-value {
    font-size: 17px;
    margin-bottom: 1px;
  }

  .en-value {
    font-size: 34px;
  }

  .car-charger-select {
    margin-top: 16px;

    .form-group {
      font-size: 14px;
    }
  }

  select.form-control.car-charger-input:not([size]):not([multiple]) {
    height: 3rem;
    cursor: pointer;
  }
}

@media (max-width: 576px) {
  .m-grid__item.m-grid__item--fluid.thank-you-cont p {
    font-size: 15px;
  }

  .btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-save-chan {
    margin-top: 0;
  }

  .modal-dialog {
    max-width: 530px;
  }

  .header-en-value {
    font-size: 1.2em;
  }

  .en-value {
    font-size: 3em;
  }

  .header-progr-bar-dashb {
    display: none;
  }
}

@media (max-width: 490px) {
  .nav.nav-pills.nav-fill {
    border-bottom: 2px solid transparent;
  }

  .navig-btn-dashb {
    width: 37px;
  }
}

// TODO: fix background
#m_login {
  background: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.options-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #161b2b;
  gap: 12px;

  .options-item {
    cursor: pointer;
    padding: 13px 24px 13px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);

    &:hover {
      background: #f0f0f0;

    }
  }
}

.checkbox-react-table {
  &>input {
    display: block;
    position: relative !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
    top: 11px !important;
    left: 12px !important;

    &:after {
      content: "";
      top: 2px;
      left: 5px;
      width: 5px;
      height: 10px;
      transform: rotate(45deg);
      border: solid #25272d;
      border-width: 0 2px 2px 0;
      display: block;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:before {
      content: "";
      background-color: #40424d;
      height: 18px;
      width: 18px;
      position: absolute;
      border-radius: 3px;
      top: -1px;
      left: -2px;
      cursor: pointer;
    }
  }

  &>input:checked:before {
    background-color: var(--primary-color);
  }

  &>input:checked:after {
    opacity: 1;
  }
}

.checkbox-react-table-all {
  input {
    display: block;
    position: relative !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
    top: 11px !important;
    left: 12px !important;

    &:after {
      content: "";
      top: 2px;
      left: 5px;
      width: 5px;
      height: 10px;
      transform: rotate(45deg);
      border: solid #25272d;
      border-width: 0 2px 2px 0;
      display: block;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:before {
      content: "";
      background-color: #40424d;
      height: 18px;
      width: 18px;
      position: absolute;
      border-radius: 3px;
      top: -1px;
      left: -2px;
      cursor: pointer;
    }
  }

  input:checked:before {
    background-color: var(--primary-color);
  }

  input:checked:after {
    opacity: 1;
  }
}