.add-gateway-preloader-container,
.installedsm-delete-prealoder-container {
  height: 185px;
  display: flex;
  align-items: center;
}

.installed-sm-expander-filters {
  row-gap: 10px;
  column-gap: 50px;
  background-color: rgba(255, 255, 255, 0.1);
}

.version-field {
  min-width: 250px;
}

.firmware-container {
  max-width: 770px;
  margin-bottom: 15px;
}

.row-gap-5 {
  row-gap: 5px;
}

.column-gap-10 {
  column-gap: 10px;
}

.modal-body-gateway {
  padding-top: 25px !important;
}

.name-or-smid-note {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
}

.confirmation-field {
  margin: 0 auto;
  padding: 0 50px;
}

#confirmDeleteGatewayForm.confirmation-field {
  padding: 0;
}

#confirmDeleteGatewayForm label,
#confirmDeleteUserForm label {
  color: #5e6774;
}

#confirmDeleteGatewayForm input,
#confirmDeleteUserForm input {
  color: #000 !important;
}

.confirmation-field>.customTextField {
  width: 100%;
}

.confirmation-field>.customTextField>label {
  padding-left: 10px;
  font-size: 16px;
}

.confirmation-field>.customTextField>.inputRoot>input {
  border: 1px solid #5e6774;
  padding-left: 10px;
  color: #000 !important;

}

.confirmation-field>.customTextField>.inputRoot>input:focus {
  border: 1px solid var(--primary-color);
  border-bottom: none;
}

.multi-select {
  font-size: 14px;
  margin: 15px 15px !important;
  color: #ffffff7f;
  margin-top: 10px !important;
}

.confirm-deleting-text {
  font-size: 16px !important;
}

#multiselectContainerReact input::placeholder {
  color: #fff;
  opacity: 1;
}
